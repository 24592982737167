<template>
  <div
    class="innerbanner_area"
    :style="
      banner
        ? `background-image: url(${banner})`
        : `background-image: url(/assets/img/terminal02.jpg)`
    "
  ></div>
</template>
<script>
import TemplateProductBanner from './TemplateProductBanner';

export default {
  mixins: [TemplateProductBanner],
  props: {
    banner: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped>
  .innerbanner_area{
    min-height:310px;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center;
  }
  @media(max-width: 1399px) {
    .innerbanner_area {min-height: 290px;}
  }
  @media (max-width:991.98px){
    .innerbanner_area {
        min-height: 190px;
    }
  }
  @media (max-width:767.98px){
    .innerbanner_area {
        min-height: 148px;
    }
  }
  @media (max-width:639.98px){
    .innerbanner_area {
        min-height: 120px;
    }
  }
  @media (max-width:479.98px){
    .innerbanner_area {
        min-height: 100px;
    }
  }
  @media (max-width:410px){
    .innerbanner_area {
        min-height: 90px;
    }
  }
</style>
